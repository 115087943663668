import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";

export default function MenuBocni(props) {
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.setState(open);
  };

  return (
    <div>
    <Drawer anchor="right" open={props.state} onClose={toggleDrawer(false)}>
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
      <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Domů" />
            </ListItemButton>
        </ListItem>        
        <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/obchodnipripady/">
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Obchodní případy" />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/nastaveni/" >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Nastavení" />
            </ListItemButton>
        </ListItem>
      </List>
    </Box>
    </Drawer>
     </div>
  );
}