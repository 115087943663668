import { useMsal } from "@azure/msal-react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { NahrajFormIdentifikaci } from "../services/IdentService";
import { NedostupnaSluzba, NeocekavanaChyba } from "./StavoveHlasky";

function IdentifikaceForm(props) {
 
  const initialValues = {
    zastupce_id: "",
    zastupce: "", 
    opcislo: "",
    opjmeno: "",
    opprijmeni: "",
    optitul: "",
    oprodcis: "",
    opdnarozeni: "",
    oppohlavi: "",
    opmistonarozeni: "",
    opstat: "",
    opdvydani: "",
    opplatnostdo: "",
    oppobyt: "",
    oprodstav: "",
    opvydal: "",
    typ:"",
  };
  
  const { instance,accounts } = useMsal();
  const [formValues, setFormValues] = useState(initialValues);
  const [status, setStatus] = useState(0);
  let [error,setError] = useState();
 
  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
   
    setFormValues({...formValues, [name]: value, });

    // if (name === "opjmeno") {
    //   let zastupce = value.trim() + " "+ formValues.opprijmeni.trim();
    //   console.log("handleInputChange" + zastupce);
    //   setFormValues({...formValues, "zastupce": zastupce,  });
    // } else if (name === "opprijmeni") {
    //   let zastupce = formValues.opjmeno.trim() + " "+ value.trim();
    //   console.log("handleInputChange" + zastupce);
    //   setFormValues({...formValues, "zastupce": zastupce,  });
    // }        
    
  };  


  const ulozIdentifikaci=(accessToken,tmp) => {
    NahrajFormIdentifikaci(accessToken,props.id,props.zastupceid,tmp).then((response) => {
      setStatus(response.status);  
      if (response.data && response.status === 200) {
        //console.log(response.data);
        props.setIdentData(props.identData.map(ident => {
        if(ident.zastupceAdresarId === response.data.zastupceAdresarId) {
          return response.data;
        } else {
          return ident;
        }
      }));
      
      }
      if (response.data && response.status > 200) setError(response.data);   
    });
  }
  
  const formOnSubmit = (event) => {

    const request = {
      ...loginRequest,
      account: accounts[0]


    };
    event.preventDefault();

    let tmp = JSON.stringify(formValues);
    //console.log(tmp);
    try {
      instance.acquireTokenSilent(request).then((response) => {
        ulozIdentifikaci(response.accessToken,tmp);
        // NahrajFormIdentifikaci(response.accessToken,props.smlouva,props.zastupceid,tmp).then((response) => {
        //   setStatus(response.status);  
        //   if (response.data && response.status === 200) {
        //     props.setIdentData(props.identData.map(ident => {
        //     if(ident.zastupceAdresarId === response.data.zastupceAdresarId) {
        //       return response.data;
        //     } else {
        //       return ident;
        //     }
        //   }));
        //   }
        //   if (response.data && response.status > 200) setError(response.data);   
        // });
      }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            ulozIdentifikaci(response.accessToken,tmp);
      //     NahrajFormIdentifikaci(response.accessToken,props.smlouva,props.zastupceid,tmp).then((response) => {
      //       setStatus(response.status);  
      //       if (response.data && response.status === 200) {
      //         props.setIdentData(response.data);
      //       } 
      //       if (response.data && response.status > 200) setError(response.data);  
      //     });
      });
      });
      } catch(e) {
        console.log(e);
    }
  }

  useEffect(() => {   
    const zastupceID = "-1";
    const defaultJmeno = "";  
    const defaultPrijmeni = "";
    const defaultTitul =  "";
    const defaultRodcis =  "";
    const defaultDNarozeni = "";
    const defaultPohlavi = "";

    // const zastupceID = props.zastupce ? props.zastupce.id : "-1";
    // const defaultJmeno = props.zastupce ? props.zastupce.adresarZastupce.odbjmeno.trim() : "";  
    // const defaultPrijmeni = props.zastupce ? props.zastupce.adresarZastupce.odbprijmeni.trim() : "";
    // const defaultTitul = props.zastupce ? props.zastupce.adresarZastupce.odbtitul.trim() : "";
    // const defaultRodcis = props.zastupce ? props.zastupce.adresarZastupce.rodcis.trim() : "";
    // const defaultDNarozeni = props.zastupce ? props.zastupce.adresarZastupce.odbdnarozeni.trim() : "";
    // const defaultPohlavi = props.zastupce && props.zastupce.adresarZastupce.pohlavi ? props.zastupce.adresarZastupce.pohlavi : "";

    const novyZastupce = {
      zastupce_id: zastupceID,
      zastupce: defaultJmeno + " " + defaultPrijmeni, 
      opcislo: "",
      opjmeno: defaultJmeno,
      opprijmeni: defaultPrijmeni,
      optitul: defaultTitul,
      oprodcis: defaultRodcis,
      opdnarozeni: defaultDNarozeni,
      oppohlavi: defaultPohlavi > 0 ? defaultPohlavi==="1" ? "1" : "2" : "0",
      opmistonarozeni: "",
      opstat: "",
      opdvydani: "",
      opplatnostdo: "",
      oppobyt: "",
      oprodstav: "",
      opvydal: "",
      typ:"0",
    };

    //console.log("novyZastupce:" + defaultPrijmeni);
  
    setFormValues(novyZastupce);
    //console.log("Ahoj");
  },[props.zastupce]);

  return (
    
    <Box component="form" onSubmit={formOnSubmit}>
    {/* <Stack spacing={2}> */}
    <Grid container direction="row" spacing={2} sx={{mb:1}}>
    <Grid item xs={12} md={6}  lg={6}><TextField fullWidth value={formValues.typ} id="typ" name="typ" select label="Typ" sx={{ mb: 1 }} onChange={handleInputChange}>      
      <MenuItem value="0" key="0">Občanský průkaz</MenuItem>
      <MenuItem value="1" key="1">Řidičský průkaz</MenuItem>
      <MenuItem value="2" key="2">Pas</MenuItem>         
    </TextField></Grid>
    {formValues.typ === "0" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opcislo} id="opcislo" name="opcislo" label="Číslo OP" variant="outlined"  sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}
    {formValues.typ === "1" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opcislo} id="opcislo" name="opcislo" label="Číslo ŘP" variant="outlined"  sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}    
    {formValues.typ === "2" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opcislo} id="opcislo" name="opcislo" label="Číslo pasu" variant="outlined"  sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}    
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opjmeno} id="opjmeno" name="opjmeno" label="Jméno" variant="outlined" sx={{ mb: 1 }}  onChange={handleInputChange}/></Grid>

    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value ={formValues.opprijmeni} id="opprijmeni" name="opprijmeni" label="Příjmení" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    
    {formValues.typ === "0" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.optitul} id="optitul" name="optitul" label="Titul" variant="outlined"  sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.oprodcis} id="oprodcis" name="oprodcis" label="Rodné číslo" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opdnarozeni} id="opdnarozeni" name="opdnarozeni" label="Datum narození" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    
    {formValues.typ === "0" || formValues.typ === "2" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.oppohlavi} id="oppohlavi" name="oppohlavi" select label="Pohlaví" sx={{ mb: 1 }} onChange={handleInputChange}>      
    <MenuItem key="0" value="0">--</MenuItem>
      <MenuItem key="1" value="1">muž</MenuItem>
      <MenuItem key="2" value="2">žena</MenuItem>            
    </TextField></Grid>
    :<div></div>}

    <Grid item xs={12} lg={6}><TextField fullWidth value={formValues.opmistonarozeni} id="opmistonarozeni" name="opmistonarozeni" label="Místo narození" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    {formValues.typ === "0" || formValues.typ === "2" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opstat} id="opstat" name="opstat" label="Státní občanství" variant="outlined" sx={{ mb: 1 }}  onChange={handleInputChange}/></Grid>
    :<div></div>}
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opdvydani} id="opdvydani" name="opdvydani" label="Datum vydání" variant="outlined" sx={{ mb: 1 }}  onChange={handleInputChange}/></Grid>
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opplatnostdo} id="opplatnostdo" name="opplatnostdo" label="Platnost do" variant="outlined" sx={{ mb: 1 }}  onChange={handleInputChange}/></Grid>
    {formValues.typ === "0" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.oppobyt} id="oppobyt" name="oppobyt" label="Trvalý pobyt" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}
    {formValues.typ === "0" ?
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.oprodstav} id="oprodstav" name="oprodstav" label="Rodinný stav" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    :<div></div>}
    <Grid item xs={12} md={6} lg={6}><TextField fullWidth value={formValues.opvydal} id="opvydal" name="opvydal" label="Vydal" variant="outlined" sx={{ mb: 1 }} onChange={handleInputChange}/></Grid>
    <Grid item xs={12} md={6} lg={12}>
      <Button variant="outlined" type="submit" startIcon={<UploadFileIcon sx={{color:'#dd9234'}} />} sx={{color:'#dd9234',borderColor:'#dd9234', marginRight: "1rem" }}>Odeslat</Button>

           {/* <Button component="label" variant="outlined" startIcon={<UploadFileIcon />} sx={{ marginRight: "1rem" }}>
        Nahrát
        <input type="file" multiple hidden onChange={(e)=> handleUploadedFiles(e)} />
      </Button>  */}
      </Grid>      
    {/* </Stack> */}
     </Grid>
     <div>{status == 200 ? <Alert severity="success">Identifikace byla úspěšně nahrána na server!</Alert>:<p></p>}</div>
     <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
     <div>{status > 200  ? <NeocekavanaChyba chyba={error} />:<p></p>}</div>  
    </Box>         
    
    );
  }

  export default IdentifikaceForm;