import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Dashboard() {

return (
<div>
<Card sx={{borderRadius:'16px',p:2,m:1}}>
<CardActionArea component={RouterLink} to="/obchodnipripady">
<Box sx={{display: 'flex',flexDirection: 'row'}}>
<CasesOutlinedIcon sx={{ mr:1,color:'#dd9234',fontSize: 40 }} />
<Typography align="justify" variant="h6" sx={{m: 'auto 0 auto 0'}} >Obchodní případy</Typography>
</Box>
</CardActionArea> 
</Card>
<Card sx={{borderRadius:'16px',bgcolor:'#dd9234',p:2,m:1}}>
<CardActionArea component={RouterLink} to="/nastaveni">
<Box sx={{display: 'flex',flexDirection: 'row'}}>
<SettingsOutlinedIcon sx={{mr:1,color:'white',bgcolor:'#dd9234', fontSize: 40 }} />
<Typography align="justify" variant="h6" sx={{color:'white',m: 'auto 0 auto 0'}} >Nastavení</Typography>
</Box>

</CardActionArea>
</Card>
</div>    
  );
}

