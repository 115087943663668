import * as React from 'react';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { cs } from 'date-fns/locale/cs';
import { useState, useEffect } from 'react';

export default function PojDialog(props) {
  const datumPojTmp = new Date(props.datumPojisteniOd);
  datumPojTmp.setHours(0,0,0,0);
  let [problem, setProblem] = useState(false);
  let [datumPojisteniOd,setDatumPojisteniOd] = useState(datumPojTmp);

  const handleClose = (sjednat) => {
    props.setPojDialogOpen(false);
    props.setSjednat(sjednat);
    if (sjednat) {
      props.setDatumPojisteniOd(datumPojisteniOd);
    }
  };

  const validaceDatumu = (datum) => {
    setProblem(isValidDate(datum));
  }

  function isValidDate(datum) {
    if (!isNaN(Date.parse(datum))) {
      let dnes = new Date();
      dnes.setHours(0, 0, 0, 0);
      if (datum < dnes) {
        return "Nelze sjednat pojištění do minulosti!";
      } else {
        setDatumPojisteniOd(datum);
        return "";
      }
    } else {
      return "Zadané datum není validní!";
    }
  }

  useEffect(() => {
    setProblem(isValidDate(datumPojisteniOd));
  }, [props.pojDialogOpen]);

  return (
    <div>
      <Dialog
        open={props.pojDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sjednat pojištění"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Zadejte datum počátku pojištění:
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
            
            <DatePicker key={cs} value={datumPojisteniOd} onChange={(newValue) => validaceDatumu(newValue)} slotProps={{ textField: { required: true } }} />
          </LocalizationProvider>
          {problem === "" ?
            <></>
            :
            <Alert sx={{ mt:1}} severity="error">{problem}</Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} autoFocus>Ne</Button>
          {problem === "" ?
            <Button onClick={() => handleClose(true)}>Ano</Button>
            :
            <Button disabled>Ano</Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
