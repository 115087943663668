import { useMsal } from "@azure/msal-react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import { Alert, Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { memo, useState } from "react";
import { loginRequest } from "../../authConfig";
import { UpravSmlouvu } from '../../services/SmlService';
import { NedostupnaSluzba, NeocekavanaChyba } from "../StavoveHlasky";
import UniCircularProgressBar from "../UniCircularProgressBar";
import { VnitrniBox } from "../VnitrniBox";


const DoplnujiciUdaje = memo(function DoplnujiciUdaje({ VIN, setVIN, id, kontrolaVIN, setKontrolaVIN, zmenaVIN }) {
    const { instance, accounts } = useMsal();
    let [statusAktualizace, setStatusAktualizace] = useState(0);
    let [progressAktualizace, setProgressAktualizace] = useState(false);
    let [VINtmp, setVINtmp] = useState(VIN);
    let [error, setError] = useState("");

    async function vratToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        try {
            let respToken = await instance.acquireTokenSilent(request);
            return respToken.accessToken;
        } catch (e) {
            let respToken = await instance.acquireTokenPopup(request);
            return respToken.accessToken;
        }
    }

    const handleAktualizovat = () => {
        setProgressAktualizace(true);
        aktualizujData();
    }

    const aktualizujData = () => {
        let data = { "karos": VINtmp };

        vratToken().then((token) => {
            UpravSmlouvu(token, id, JSON.stringify(data)).then(response => {
                console.log(response);
                if (response.status > 200) setError(response.data);
                if (response.status === 200) {
                    setVIN(VINtmp);
                }
                setStatusAktualizace(response.status);
                setProgressAktualizace(false);
            });
        });
    }

    const handleChange = (event) => {
        setKontrolaVIN(event.target.checked);
    };

    return (
        <div>
            {zmenaVIN ?
                <VnitrniBox nadpis={"Doplnění VIN"}>
                    <div>
                        <Box sx={{ display: "flex", paddingTop: 1, gap: { xs: 1 }, alignItems: { xs: "flex-start", md: "center" }, flexDirection: { xs: 'column', md: 'row' } }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <TextField

                                    label="VIN"
                                    id="doplneni-vin"
                                    value={VINtmp}
                                    sx={{ width: { sm: "400px" } }}
                                    onChange={(event) => {
                                        setVINtmp(event.target.value);
                                    }}
                                    onInput={(event) => {
                                        event.target.value = event.target.value.toUpperCase();
                                    }}
                                />
                                {VINtmp.length === 17 ?
                                    <CheckCircleIcon sx={{ color: '#dd9234' }} />
                                    :
                                    <></>
                                }
                            </Box>
                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                <Button disabled={VINtmp.length === 0} onClick={handleAktualizovat} sx={{ borderColor: '#dd9234', color: '#dd9234' }} component="label" variant="outlined" startIcon={<SystemUpdateAltOutlinedIcon sx={{ color: '#dd9234' }} />}>AKTUALIZOVAT</Button>
                                {progressAktualizace ? <UniCircularProgressBar /> : <></>}
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <div>{statusAktualizace === 200 ? <Alert severity="success">Údaje byly úspěšně zaktualizovány!</Alert> : <p></p>}</div>
                            <div>{statusAktualizace === -1 ? <NedostupnaSluzba /> : <p></p>}</div>
                            <div>{statusAktualizace > 200 ? <NeocekavanaChyba chyba={error} /> : <p></p>}</div>
                        </Box>
                    </div>
                </VnitrniBox>
                :
                <VnitrniBox nadpis={"Kontrola VIN"}>
                    <div>
                        {VINtmp === "" ?
                            <Alert severity='warning'>VIN není vyplněno a předmět není ve výrobě. V tuto chvíli nelze sjednat pojištění.</Alert>
                            :
                            <Box sx={{ display: "flex", width: "100%", paddingTop: 1, gap: { xs: 1 }, flexDirection: { xs: 'column', md: 'row' } }}>
                                <TextField
                                    label="VIN"
                                    disabled
                                    id="kontrola-vin"
                                    value={VINtmp}
                                    sx={{ width: { xs: "100%", md: "80%" } }}
                                />
                                <Box sx={{ display: "flex", alignItems: 'center' }}>
                                    {/* <Button onClick={handleAktualizovat} sx={{ borderColor: '#dd9234', color: '#dd9234', width: { xs: "50%", md: "200px" } }} component="label" variant="outlined" startIcon={<SystemUpdateAltOutlinedIcon sx={{ color: '#dd9234' }} />}>AKTUALIZOVAT</Button>
                  {progress ? <UniCircularProgressBar /> : <></>} */}
                                    <FormControlLabel control={<Checkbox checked={kontrolaVIN} onChange={handleChange} sx={{ color: "#dd9234", '&.Mui-checked': { color: "#dd9234", }, }} />} label="Zkontrolováno" />
                                </Box>
                            </Box>
                        }
                    </div>
                </VnitrniBox>
            }
        </div>
    );
});

export default DoplnujiciUdaje;