import { CircularProgress } from '@mui/material';


function UniCircularProgressBar() {

return (
    <CircularProgress size={25} sx={{ml:1}} />
);  
}

export default UniCircularProgressBar;