import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from '@mui/material';
import React from "react";
import { IconContext } from "react-icons";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import Email from "./Email";

const styles = {
  accorPopis: {
    fontWeight: 'bold',
  },
  odkaz: {
    color: '#dd9234',
  },
  stavTextEx: {
    lineHeight: '25px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#8FD14F',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
  },
  stavText: {
    lineHeight: '25px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#F29400',
    borderRadius: '8px',
    pl: 1,
    pr: 1,
  },
  nadpis: {
    width: '100px',
    backgroundColor: '#3C486B',
    color: 'white',
    p: "1px",
    borderRadius: '16px',
    mt: "-28px",
    mb: 1
  },
  nadpisDoc: {
    width: '200px',
    backgroundColor: '#3C486B',
    color: 'white',
    p: "1px",
    borderRadius: '16px',
    mt: "-28px",
    mb: 1
  },
  sekce: {
    flex: "50%",
    p: 2,
    ml: 1,
    mr: 1,
    mt: 1,
    mb: 2,
    borderRadius: '16px',
    borderColor: '#3C486B',
  },
  uniokbadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#088443',
    }
  },
  uniproblembadge: {
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#a52b71',
    }
  }
};

export default function Detail(props) {
  //console.log(props.sml);
  const formatKontaktu = (nazev, ico, ulice, psc, obec, mobil, email) => {
    return (
      <div>
        <b>{nazev}</b> (IČ: {ico})<br />
        {ulice}<br />
        {psc} {obec}<br />
        <a style={{ color: "#F29400" }} href={"mailto:" + email}>{email}</a> / <a style={{ color: "#F29400" }} href={"tel:" + mobil}>{mobil}</a><br /><br />
      </div>
    );
  }

  const formatZastupce = (jmeno, prijmeni, funkce, rodcis, mobil, email) => {
    return (
      prijmeni !== '' ?
        <div>
          {jmeno + " " + prijmeni}, {funkce} (RČ: {rodcis})<br />

          <a style={{ color: "#F29400" }} href={"mailto:" + email}>{email}</a>
          {email !== "" && mobil !== "" ? <> / </> : <></>}
          <a style={{ color: "#F29400" }} href={"tel:" + mobil}>{mobil}</a><br /><br />
        </div>
        :
        <></>
    );
  }


  return (
    <Accordion>

      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={styles.accorSumm}
      >
        <Typography sx={styles.accorPopis}>Detail</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: '5px', textAlign: 'center' }}>

          {props.sml.stavop !== 'EX' ?
            <Typography sx={styles.stavText}>{props.sml.stavText}</Typography>
            :
            <Typography sx={styles.stavTextEx}>{props.sml.stavText}</Typography>
          }
          <Typography variant="caption" sx={{ fontStyle: 'italic' }}>{props.sml.kdyulozilstavko}</Typography>
          <Typography variant="caption" sx={{ fontStyle: 'italic' }}>{props.sml.kdoulozilstavko}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>

          {props.sml.zaplaceno > 0 ?

            <Box sx={{ mr: 1, backgroundColor: "#8FD14F", width: "35px", height: "35px", lineHeight: "35px", borderRadius: "8px" }}>
              <IconContext.Provider value={{ style: { display: 'block', height: '100%', margin: 'auto' } }}>
                <RiMoneyEuroBoxFill color="white" size="25px" />
              </IconContext.Provider>
            </Box>
            :
            <></>
          }
        </Box>

      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' } }}>
          <Paper variant="outlined" sx={styles.sekce}>
            <Typography align="center" sx={styles.nadpis}>Platba</Typography>

            {
              props.sml.zaplaceno > 0 ?
                <Typography>
                  <b>{JSON.parse(props.sml.platba_info).castka} {JSON.parse(props.sml.platba_info).mena}</b> ({JSON.parse(props.sml.platba_info).banka}) <br />
                  <i>{JSON.parse(props.sml.platba_info).datum}, {JSON.parse(props.sml.platba_info).ulozil}</i>
                </Typography>
                :
                <Typography><i>Smlouva nebyla zatím zaplacena.</i></Typography>
            }
            <Email cislols={props.sml.smlouva} />
          </Paper>
          <Paper variant="outlined" sx={styles.sekce}>
            <Typography align="center" sx={styles.nadpis}>Podmínky</Typography>
            <Typography>
              <b>{props.sml.sazbaDPH}</b><br />
              {props.sml.podminky}<br />
              Datum předání: {props.sml.dpredani}<br />
            </Typography>
          </Paper>
        </Box>
        <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' } }}>
          <Paper variant="outlined" sx={styles.sekce}>
            <Typography align="center" sx={styles.nadpis}>Předmět</Typography>
            <Typography>
              <b>{props.sml.nazev}</b><br />
              VIN: {props.VIN}<br />
              RZ: {props.sml.spz}<br />
              Rok výroby: {props.sml.rokvyr}<br />
              Km/mth: {props.sml.stavtach}<br />
              Cena bez DPH: {props.sml.cenabezdph} {props.sml.mena}<br />
              Cena s DPH: {props.sml.cenasdph} {props.sml.mena}<br />
            </Typography>
          </Paper>
        </Box>
        <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' } }}>
          <Paper variant="outlined" sx={styles.sekce}>
            <Typography align="center" sx={styles.nadpis}>Klient</Typography>
            <Typography component={"div"}>
              {props.sml.idnajemce > 0 ?
                <div>
                  {formatKontaktu(props.sml.odb, props.sml.odbico, props.sml.odbulice, props.sml.odbpsc, props.sml.odbobec, props.sml.odbmobil0, props.sml.odbemail0)}

                  {props.sml.odbzast !== "" || props.sml.odbzast2 !== "" ?
                    <Box>
                      Zástupci:<br />
                      {formatZastupce(props.sml.odbzjmeno, props.sml.odbzast, props.sml.odbzfunkce, props.sml.odbzrodcis, props.sml.odbmobil, props.sml.odbmail)}
                      {formatZastupce(props.sml.odbzjmeno2, props.sml.odbzast2, props.sml.odbzfunkce2, props.sml.odbzrodcis2, props.sml.odbmobil2, props.sml.odbemail2)}
                    </Box>
                    :
                    <></>
                  }
                </div>
                :
                <Typography><i>Na smlouvě nebyl zadán nájemce.</i></Typography>
              }
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={styles.sekce}>
            <Typography align="center" sx={styles.nadpis}>Dodavatel</Typography>
            <Typography component={"div"}>
              {props.sml.iddodavatele > 0 ?
                <div>
                  {formatKontaktu(props.sml.dod, props.sml.dodico, props.sml.dodulice, props.sml.dodpsc, props.sml.dodobec, props.sml.dodmobil0, props.sml.dodemail0)}
                  {props.sml.dodzast !== "" || props.sml.dodzast2 !== "" ?
                    <Box>
                      Zástupci:<br />
                      {formatZastupce(props.sml.dodzjmeno, props.sml.dodzast, props.sml.dodfunkce, props.sml.dodrodcis, props.sml.dodmobil, props.sml.dodmail)}
                      {formatZastupce(props.sml.dodzjmeno2, props.sml.dodzast2, props.sml.dodfunkce2, props.sml.dodrodcis2, props.sml.dodmobil2, props.sml.dodemail2)}
                    </Box>
                    :
                    <></>
                  }
                </div>
                :
                <Typography><i>Na smlouvě nebyl zadán dodavatel.</i></Typography>
              }

            </Typography>
          </Paper>
        </Box>

      </AccordionDetails>

    </Accordion>
  );
}

