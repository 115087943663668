import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button } from '@mui/material';
import Compressor from 'compressorjs';
import React from "react";


export const UniUploadButton =(props) => {

  async function compressImages(files, output){
    return new Promise(async function(resolve){
        let numProcessedImages = 0
        let numImagesToProcess = files.length;
        for (let i = 0; i < numImagesToProcess; i++){
            const file = files[i];
            if (file.name.split('.').pop().toLowerCase() === 'pdf') {
              //console.log('pdf');
              output.push(file);
            } else {
              //console.log('image');
              await new Promise(resolve =>{
                  new Compressor(file, {
                      quality: 0.8,
                      retainExif: true,
                      success(result){                        
                          output.push(new File([result], file.name))
                          resolve()
                      }
                  })
              })
            }

            numProcessedImages += 1
        }
        if (numProcessedImages === numImagesToProcess){
            resolve()
        }
    })
  }

  const handleUploadedFiles = (files) => {
    
    let compressedImages = [];
    compressImages(files, compressedImages).then(() => {
      //console.log(compressedImages);
      if(props.typ=== -1) {
        props.handleUploadedFiles(compressedImages);
      } else {
        props.handleUploadedFiles(compressedImages,props.typ);
      }
    }
    );
  };

    return (
      <Button component="label" variant="outlined" startIcon={<FileUploadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234'}}>
        Nahrát
        {props.pdf ?
          <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" multiple hidden onChange={(e)=> handleUploadedFiles(e.target.files)} />          
        :
          <input type="file" accept="image/jpeg,image/gif,image/png" multiple hidden onChange={(e)=> handleUploadedFiles(e.target.files)} />          
        }
      </Button>
    );
  }